import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Grid, Box, TextField, Button, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const AddLuckyDrawy = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [trips, setTrips] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const loadTrips = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await apiService.get("/lottery/lotteryTrip", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const loadedTrips = response.data.lotteryTrips;
        setTrips(loadedTrips);
        setLoading(false);
        console.log(loadedTrips);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    loadTrips();
  }, []);

  const [formData, setFormData] = useState({
    lotteryTrip: "",
    joinPrice: "",
    endTime: null,
  });

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleDateChange = (field) => (date) => {
    setFormData({ ...formData, [field]: date });
  };

  const handleAddLuckyDraw = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await apiService.post("/lottery/lottery", formData, {
        headers: headers,
      });

      if (response.data.success) {
        console.log("Lucky draw API:", response.data);
        setLoading(false);
        navigate("/lucky-draws");
      } else {
        console.error("Error:", response.data.error);
        setLoading(false);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error", error);
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error adding a new lucky draw!"
      />

      <Typography variant="h3" sx={style.heading}>
        Add a new lucky draw
      </Typography>

      <Grid container gap={4} sx={style.flex}>
        <Grid sx={style.formContainer} gap={2}>
          <Box>
            <Typography variant="h6">Select Lucky Draw Trip</Typography>
            <FormControl sx={{ paddingY: 1, minWidth: 120 }}>
              <Select
                value={formData.lotteryTrip}
                onChange={(e) =>
                  setFormData({ ...formData, lotteryTrip: e.target.value })
                }
              >
                {trips ? (
                  trips.map((trip, index) => (
                    <MenuItem value={trip._id}>{trip.name}</MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Select a trip</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Typography variant="h6">End time</Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Select end time"
                  value={formData.endTime}
                  onChange={handleDateChange("endTime")}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box gap={2}>
            <Typography variant="h6">Enter join price</Typography>
            <TextField
              size="small"
              value={formData.joinPrice}
              onChange={handleInputChange("joinPrice")}
            />
          </Box>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        sx={style.addBtn}
        onClick={handleAddLuckyDraw}
      >
        <Typography variant="body2">Add lucky draw</Typography>
      </Button>
    </Layout>
  );
};

export default AddLuckyDrawy;
