import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FlightIcon from "@mui/icons-material/Flight";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import RedeemIcon from "@mui/icons-material/Redeem";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Check } from "@mui/icons-material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link, useLocation } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import PhotoIcon from "@mui/icons-material/Photo";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import logo from "../../images/gpp_logo_text_white.svg";

import style from "./style";

const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(null);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Grid container item md={2} sm={3} sx={style.sideMenu}>
      <img src={logo} alt="SVG as an image" />
      <Box style={{ height: "20px" }}></Box>
      <Typography variant="subtitle2">Trips</Typography>

      <Grid sx={style.menuItems}>
        <Link style={style.link} to="/trips">
          <Grid
            sx={
              activeLink === "/trips" ||
                activeLink === "/new-trip" ||
                (activeLink && activeLink.startsWith("/edit-trip/")) ||
                (activeLink && activeLink.startsWith("/view-trip-details/"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <FlightIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Trips</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Services
        </Typography>

        <Link style={style.link} to="/services">
          <Grid
            sx={
              activeLink === "/services" ||
                activeLink === "/new-service" ||
                (activeLink && activeLink.startsWith("/edit-service/"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <MiscellaneousServicesIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Services</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Categories
        </Typography>

        <Link style={style.link} to="/categories">
          <Grid
            sx={
              activeLink === "/categories" ||
                activeLink === "/new-category" ||
                (activeLink && activeLink.startsWith("/edit-category/"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <CategoryIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Categories</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Addons
        </Typography>

        <Link style={style.link} to="/addons">
          <Grid
            sx={
              activeLink === "/addons" ||
                activeLink === "/new-addon" ||
                (activeLink && activeLink.startsWith("/edit-addon/"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <CategoryIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Addons</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Custom Tours
        </Typography>

        <Link style={style.link} to="/customTours">
          <Grid
            sx={
              activeLink === "/customTours" 
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <CategoryIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Custom Tours</Typography>
          </Grid>
        </Link>
        <Typography variant="subtitle2" sx={style.setting}>
          Bookings
        </Typography>
        <Link style={style.link} to="/bookings">
          <Grid
            sx={
              activeLink === "/bookings" ||
                (activeLink && activeLink.startsWith("/edit-booking"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <BookOnlineIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Bookings</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Tickets
        </Typography>

        <Link style={style.link} to="/tickets">
          <Grid
            sx={
              activeLink === "/tickets" ||
                activeLink === "/new-ticket" ||
                (activeLink && activeLink.startsWith("/edit-ticket"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <ConfirmationNumberIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Tickets</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Transactions
        </Typography>

        <Link style={style.link} to="/view-transactions">
          <Grid
            sx={
              activeLink === "/view-transactions" ||
                (activeLink && activeLink.startsWith("/view-transactions"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <ReceiptLongIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Transactions</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Lucky Draws
        </Typography>

        <Link style={style.link} to="/lucky-draws">
          <Grid
            sx={
              activeLink === "/lucky-draws" ||
                activeLink === "/new-lucky-draw" ||
                (activeLink && activeLink.startsWith("/view-lucky-draw/"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <RedeemIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Lucky Draws</Typography>
          </Grid>
        </Link>

        <Link style={style.link} to="/lucky-draw-trips">
          <Grid
            sx={
              activeLink === "/lucky-draw-trips" ||
                activeLink === "/new-lucky-draw-trip"
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <FlightIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Lucky Draws Trips</Typography>
          </Grid>
        </Link>

        <Link style={style.link} to="/completed-lucky-draws">
          <Grid
            sx={
              activeLink === "/completed-lucky-draws"
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <Check fontSize="small" sx={style.icon} />
            <Typography variant="body2">Completed Lucky Draws</Typography>
          </Grid>
        </Link>
        <Typography variant="subtitle2" sx={style.setting}>
          Slider
        </Typography>
        <Link style={style.link} to="/slider-images">
          <Grid
            sx={
              activeLink === "/slider-images" ||
                activeLink === "/new-slider-image"
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <PhotoIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Slider Images</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Users
        </Typography>

        <Link style={style.link} to="/users">
          <Grid
            sx={
              activeLink === "/users" ||
                activeLink === "/new-user" ||
                (activeLink && activeLink.startsWith("/edit-user"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <PeopleIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Users</Typography>
          </Grid>
        </Link>

        <Typography variant="subtitle2" sx={style.setting}>
          Settings
        </Typography>
        <Link style={style.link} to="/settings">
          <Grid
            sx={
              activeLink === "/settings" ||
                (activeLink && activeLink.startsWith("/settings"))
                ? style.menuItemSelected
                : style.menuItem
            }
          >
            <SettingsOutlinedIcon fontSize="small" sx={style.icon} />
            <Typography variant="body2">Settings</Typography>
          </Grid>
        </Link>

        <Grid sx={style.menuItem} onClick={handleLogout}>
          <LogoutIcon fontSize="small" sx={style.icon} />
          <Typography variant="body2">Logout</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SideMenu;
