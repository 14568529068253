import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Box, Button, Switch, Typography } from "@mui/material";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Confirm from "../../components/ConfirmMsg";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

function LuckyDrawTrips() {
  const navigate = useNavigate();
  const [settings, setSettings] = useState();
  const [trips, setTrips] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tripIdToDelete, setTripIdToDelete] = useState(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [openToggleSnackbar, setOpenToggleSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [message, setMessage] = useState();

  const handleNewTrip = () => {
    navigate("/new-lucky-draw-trip");
  };

  const handleOpen = (categoryId) => {
    setTripIdToDelete(categoryId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTripIdToDelete(null);
  };

  const handleDelete = async () => {
    setOpen(false);
    setLoading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const deleteResponse = await apiService.delete(
        `/lottery/deleteLotteryTrip/${tripIdToDelete}`,
        {
          headers,
        }
      );
      setMessage(deleteResponse.data.message);

      if (deleteResponse.data.success === true) {
        const response = await apiService.get(`/lottery/lotteryTrip`);
        const updatedTrip = response.data.trips;

        console.log("Trips deleted successfully");
        setTrips(updatedTrip);

        setLoading(false);
        setOpenSuccessSnackbar(true);
      } else {
        setLoading(false);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting trip:", error);
      setMessage(error);
      setLoading(false);
      setOpenSnackbar(true);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("token");

    apiService
      .get("/lottery/lotteryTrip", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const loadedTrips = response.data.lotteryTrips;

        setTrips(loadedTrips);
        setLoading(false);
        console.log(loadedTrips);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [message]);

  return (
    <Layout>
      <Loader open={loading} />
      <Confirm
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text={message}
      />

      <AlertMessage
        open={openSuccessSnackbar}
        onClose={() => setOpenSuccessSnackbar(false)}
        severity="success"
        text={message}
      />

      <Grid container sx={style.container}>
        <Typography variant="h3">Luck Draw Trips</Typography>
        <Button variant="contained" sx={style.button} onClick={handleNewTrip}>
          <Typography variant="body2">Add new trip</Typography>
        </Button>
      </Grid>
      <Grid container gap={5} sx={style.container}>
        {trips ? (
          trips.map((trip, index) => (
            <Box style={{ width: "100%" }}>
              <Grid
                item
                md={2.32}
                container
                gap={1}
                sx={style.block}
                key={index}
              >
                <Box sx={style.title}>
                  <Typography variant="h4">{trip.name}</Typography>
                </Box>
                <Box sx={style.wrap} gap={1}>
                  <Button
                    sx={style.btn}
                    variant="outlined"
                    // onClick={() => handleViewDetails(trip._id, trip)}
                  >
                    <Typography variant="body2">View Details</Typography>
                  </Button>

                  <Box>
                    <EditIcon
                      sx={style.edit}
                      // onClick={() => handleEditTrip(trip._id, trip)}
                    />
                    <DeleteIcon
                      color="error"
                      sx={style.delete}
                      onClick={() => handleOpen(trip._id)}
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          ))
        ) : (
          <NoData text="trips" />
        )}
      </Grid>
    </Layout>
  );
}

export default LuckyDrawTrips;
