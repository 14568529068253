import React from "react";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Trips from "./pages/Trips";
import Users from "./pages/Users";
import PrivateRoutes from "./routes";
import Tickets from "./pages/Tickets";
import Bookings from "./pages/Bookings";
import Services from "./pages/Services";
import LuckyDraw from "./pages/LuckyDraw";
import Categories from "./pages/Categories";
import { theme } from "../src/styles/Theme";
import AddTrip from "./pages/Trips/AddTrip";
import EditTrip from "./pages/Trips/EditTrip";
import AddTicket from "./pages/Tickets/AddTicket";
import TripDetails from "./pages/Trips/TripDetails";
import AddService from "./pages/Services/AddService";
import EditService from "./pages/Services/EditServices";
import AddCategory from "./pages/Categories/AddCategory";
import AddLuckyDraw from "./pages/LuckyDraw/AddLuckyDraw";
import EditCategory from "./pages/Categories/EditCategory";
import LuckyDrawDetails from "./pages/LuckyDraw/LuckyDrawDetails";
import SettingsPage from "./pages/Settings";
import LuckyDrawTrips from "./pages/LuckyDrawTrips";
import AddLuckDrawTrip from "./pages/LuckyDrawTrips/AddLuckyDrawTrip";
import AppDashboardSlider from "./pages/Slider";
import AddSliderImage from "./pages/Slider/AddSlider";
import CompletedLuckyDraws from "./pages/LuckyDraw/completeLuckyDraws";
import AnnounceWinner from "./pages/LuckyDraw/AnnounceWinner";
import CustomTours from "./pages/CustomTours";
import Transactions from "./pages/Transactions";
import Addons from "./pages/Addons";
import AddAddon from "./pages/Addons/AddAddon";
import EditAddon from "./pages/Addons/EditAddons";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/trips" element={<Trips />} />
            <Route path="/addons" element={<Addons />} />
            <Route path="/new-addon" element={<AddAddon />} />
            <Route path="/edit-addon/:addonId" element={<EditAddon />} />

            <Route path="/settings" element={<SettingsPage />} />

            <Route
              path="/view-trip-details/:tripId"
              element={<TripDetails />}
            />
            <Route path="/new-trip" element={<AddTrip />} />
            <Route path="/edit-trip/:tripId" element={<EditTrip />} />
            <Route path="/services" element={<Services />} />
            <Route path="/new-service" element={<AddService />} />
            <Route path="/edit-service/:serviceId" element={<EditService />} />
            <Route path="/customTours" element={<CustomTours />} />

            <Route path="/categories" element={<Categories />} />
            <Route path="/new-category" element={<AddCategory />} />
            <Route
              path="/edit-category/:categoryId"
              element={<EditCategory />}
            />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/new-ticket" element={<AddTicket />} />
            <Route path="/lucky-draws" element={<LuckyDraw />} />
            <Route
              path="/completed-lucky-draws"
              element={<CompletedLuckyDraws />}
            />
            <Route path="/view-transactions" element={<Transactions />} />
            <Route
              path="/lucky-draw-announce-winner"
              element={<AnnounceWinner />}
            />

            <Route path="/lucky-draw-trips" element={<LuckyDrawTrips />} />

            <Route path="/new-lucky-draw-trip" element={<AddLuckDrawTrip />} />
            <Route path="/slider-images" element={<AppDashboardSlider />} />
            <Route path="/new-slider-image" element={<AddSliderImage />} />

            <Route
              path="/view-lucky-draw/:luckyDrawId"
              element={<LuckyDrawDetails />}
            />
            <Route path="/new-lucky-draw" element={<AddLuckyDraw />} />
            <Route path="/users" element={<Users />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
