import {
  Box,
  Grid,
  Radio,
  Button,
  Divider,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const LuckyDrawDetails = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState("");

  const initialLuckyDrawData = state?.luckyDrawDetails;
  const [formData] = useState({
    _id: initialLuckyDrawData._id,
    lotteryTrip: initialLuckyDrawData.lotteryTrip,
    name: initialLuckyDrawData.name,
    winnerSelected: initialLuckyDrawData.winnerSelected,
    joinPrice: initialLuckyDrawData.joinPrice,
    winPrice: initialLuckyDrawData.winPrice,
    endTime: initialLuckyDrawData.endTime,
    participants: initialLuckyDrawData.participants,
    winner: initialLuckyDrawData.winner,
  });

  const handleParticipantChange = (participantID) => {
    setSelectedParticipant(participantID);
  };

  const handleWinner = async () => {
    if (selectedParticipant) {
      try {
        setLoading(true);

        // Prepare the request body
        const requestBody = {
          lotteryId: formData._id,
          userId: selectedParticipant,
        };

        // Make API call to set the winner
        const response = await apiService.post(
          "/lottery/setWinner",
          requestBody
        );

        console.log("Set winner API:", response.data.message);
        setOpenSuccessSnackbar(true);
      } catch (error) {
        console.error("Error setting winner:", error);
        setOpenSuccessSnackbar(true);
      } finally {
        setLoading(false);
      }
    } else {
      setOpenSnackbar(true);
    }
  };
  const formatDateString = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="No participant is selected!"
      />

      <AlertMessage
        open={openSuccessSnackbar}
        onClose={() => setOpenSuccessSnackbar(false)}
        severity="success"
        text="The winner has been declared!"
      />

      <Grid container gap={3} sx={style.detailBlock}>
        <Grid container sx={style.wrapper}>
          <Typography variant="h3">{formData.name}</Typography>

          <Box sx={style.action} gap={2}>
            <Button
              variant="contained"
              onClick={handleWinner}
              sx={style.button}
            >
              <Typography variant="body2">Set Winner</Typography>
            </Button>
          </Box>
        </Grid>

        <Divider />

        <Grid container gap={2}>
          <Box sx={style.categories} gap={1}>
            <Typography variant="h5">Join Price</Typography>
            <Typography variant="body1">{formData.joinPrice}</Typography>
          </Box>

          <Box sx={style.categories} gap={1}>
            <Typography variant="h5">Participants</Typography>
            <Typography variant="body1">
              {formData.participants.length}
            </Typography>
          </Box>

          <Box sx={style.categories} gap={1}>
            <Typography variant="h5">Win Trip</Typography>
            <Typography variant="body1">{formData.lotteryTrip.name}</Typography>
          </Box>
          {formData.winner ? (
            <Box sx={style.categories} gap={1}>
              <Typography variant="h5">Winner Announced</Typography>
              <Typography variant="body1">{formData.winner.name}</Typography>
              <Typography variant="body1">{formData.winner.email}</Typography>
              <Typography variant="body1">
                {formData.winner.phoneNumber}
              </Typography>
            </Box>
          ) : (
            <Box sx={style.categories} gap={1}>
              <Typography variant="h5">Winner</Typography>
              <Typography variant="body1">No Winner</Typography>
            </Box>
          )}

          <Box sx={style.categories} gap={1}>
            <Typography variant="h5">End Time</Typography>
            <Typography variant="body1">
              {formatDateString(formData.endTime)}
            </Typography>
          </Box>
        </Grid>

        <Box>
          <Typography variant="h5">Participants:</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {formData.participants?.map((value, index) => (
                <Box
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  gap={2}
                >
                  <FormControlLabel
                    key={index}
                    value={value._id}
                    control={
                      <Radio
                        onChange={() => handleParticipantChange(value._id)}
                      />
                    }
                  />
                  <Avatar alt={index} src={value.profilePic}></Avatar>
                  <Typography>{value.name}</Typography>
                  <Typography>
                    {value.email
                      ? `${value.email.slice(0, 2)}*****${value.email.slice(
                          value.email.indexOf("@")
                        )}`
                      : `${value.phoneNumber.slice(
                          0,
                          4
                        )}******${value.phoneNumber.slice(-3)}`}
                  </Typography>
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Grid>
    </Layout>
  );
};

export default LuckyDrawDetails;
