const style = {
  container: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default style;
