import {
  Box,
  Grid,
  Radio,
  Button,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  FormGroup,
  InputLabel,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";
import { DateTimePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles({
  twoColumns: {
    columnCount: 2,
    marginTop: 10,
  },
});

const AddTrip = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [addoOns, setAddOns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentDate, setCurrentDate] = useState();
  const [selectedDates, setSelectedDates] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: "",
    couplePrice: "",
    days: "",
    startDate: "",
    status: "",
    services: "",
    image: [],
    category: "",
    bookingAmount: "",
    totalSeats: "",
    loyaltyPoints: "",
  });

  useEffect(() => {
    setLoading(true);

    // Fetch services
    apiService
      .get("/services/service")
      .then((response) => {
        const service = response.data.services;
        setServices(service);

      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });

    apiService
      .get("/addons/get")
      .then((response) => {
        const fetchedAddons = response.data.addons;
        setAddOns(fetchedAddons);


      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });

    // Fetch categories
    apiService
      .get("/categories/category")
      .then((response) => {
        const category = response.data.categories;
        setCategories(category);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDates]);

  const handleDateChange = (field) => (date) => {
    setSelectedDates([...selectedDates, date]);
    setFormData({ ...formData, [field]: date });
    console.log(selectedDates);
  };

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleServiceChange = (serviceId) => {
    setSelectedServices((prevSelectedServices) => {
      const isSelected = prevSelectedServices.includes(serviceId);

      if (isSelected) {
        // If serviceId is already in the list, remove it
        const updatedServices = prevSelectedServices.filter(
          (id) => id !== serviceId
        );
        return updatedServices;
      } else {
        // If serviceId is not in the list, add it
        const updatedServices = [...prevSelectedServices, serviceId];
        return updatedServices;
      }
    });
  };

  const handleAddOnChange = (addoOnId) => {
    setSelectedAddOns((prevSelectedAddons) => {
      const isSelected = prevSelectedAddons.includes(addoOnId);

      if (isSelected) {
        // If serviceId is already in the list, remove it
        const updatedAddOn = prevSelectedAddons.filter(
          (id) => id !== addoOnId
        );
        return updatedAddOn;
      } else {
        // If serviceId is not in the list, add it
        const updatedAddOn = [...prevSelectedAddons, addoOnId];
        return updatedAddOn;
      }
    });
  };

  const handleDeleteDate = (index) => {
    const updatedDates = [...selectedDates];
    updatedDates.splice(index, 1);
    setSelectedDates(updatedDates);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setFormData({ ...formData, image: files });
  };

  const handleAddTrip = () => {
    setLoading(true);

    const formDataForApi = new FormData();
    formDataForApi.append("name", formData.name);
    formDataForApi.append("description", formData.description);
    formDataForApi.append("price", formData.price);
    formDataForApi.append("couplePrice", formData.couplePrice);
    formDataForApi.append("days", formData.days);

    // Parse and format the start and end dates
    const startDate = new Date(formData.startDate);

    for (const startDate of selectedDates) {
      formDataForApi.append("startDate", startDate.toISOString());
    }

    formDataForApi.append("status", formData.status);
    formDataForApi.append("category", selectedCategory);
    formDataForApi.append("bookingAmount", formData.bookingAmount);
    formDataForApi.append("seatOfChoicePrice", formData.seatOfChoicePrice);
    formDataForApi.append("totalSeats", formData.totalSeats);
    formDataForApi.append("loyaltyPoints", formData.loyaltyPoints);

    for (const serviceId of selectedServices) {
      formDataForApi.append("services", serviceId);
    }
    for (const addonId of selectedAddOns) {
      formDataForApi.append("addons", addonId);
    }
    formData.image.forEach((file) => {
      formDataForApi.append("image", file);
    });

    apiService
      .post("/trips/trip", formDataForApi)
      .then((response) => {
        if (response.data.success) {
          console.log("Trip added successfully:", response.data);
          setLoading(false);
          navigate("/trips");
        } else {
          console.error("Error:", response.data);
          setLoading(false);
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error("Error posting:", error);
        setLoading(false);
        setOpenSnackbar(true);
      });
  };

  const getFormattedDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error adding a new trip!"
      />

      <Typography variant="h3" sx={style.heading}>
        Add a new trip
      </Typography>

      <Grid container gap={8} sx={style.flex}>
        <Grid container item md={5} sx={style.formContainer} gap={4}>
          <Box sx={style.form} gap={2}>
            <Typography variant="h6">Enter Name</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.name}
              onChange={handleInputChange("name")}
            />
          </Box>

          <Box sx={style.desc} gap={1}>
            <Typography variant="h6">Enter description:</Typography>
            <ReactQuill
              value={formData.description}
              onChange={(value) =>
                setFormData({ ...formData, description: value })
              }
            />
          </Box>

          <Box sx={style.formContainer}>
            <Typography variant="h6">Add start date:</Typography>
            <Grid container alignItems="center" justify="space-between" gap={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    label="Start date"
                    value={formData.startDate}
                    onAccept={handleDateChange("startDate")}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Box>
          {selectedDates.length > 0 && (
            <Box sx={style.formContainer} gap={2}>
              <Typography variant="h6">Selected Start Dates</Typography>
              {selectedDates.map((item, index) => (
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  gap={2}
                  key={index} // Add a unique key for each grid container
                >
                  <Typography variant="h6" color="green">
                    {getFormattedDate(item)}
                  </Typography>
                  <div style={{ marginLeft: "auto" }}>
                    <DeleteForeverIcon
                      style={{ color: "red" }}
                      onClick={() => handleDeleteDate(index)}
                    />
                  </div>
                </Grid>
              ))}
            </Box>
          )}

          <Box>
            <Typography variant="h6">Choose services:</Typography>
            <Box className={classes.twoColumns}>
              {services?.map((value, index) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedServices.includes(value._id)}
                        onChange={() => handleServiceChange(value._id)}
                      />
                    }
                    label={value.name}
                  />
                </FormGroup>
              ))}
            </Box>
          </Box>

          <Box>
            <Typography variant="h6">Choose Add On Services:</Typography>
            <Box className={classes.twoColumns}>
              {addoOns?.map((value, index) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAddOns.includes(value._id)}
                        onChange={() => handleAddOnChange(value._id)}
                      />
                    }
                    label={value.name + " - " + value.price + " PKR"}
                  />
                </FormGroup>
              ))}
            </Box>
          </Box>
        </Grid>

        <Grid sx={style.formContainer} gap={4}>
          <Box sx={style.form}>
            <Typography variant="h6">Enter Price with Standard Seat</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.price}
              onChange={handleInputChange("price")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Enter Couple Price (For 2 Seats )</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.couplePrice}
              onChange={handleInputChange("couplePrice")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Enter Days</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.days}
              onChange={handleInputChange("days")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Enter total seats</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.totalSeats}
              onChange={handleInputChange("totalSeats")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Enter loyalty points</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.loyaltyPoints}
              onChange={handleInputChange("loyaltyPoints")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Enter booking amount</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.bookingAmount}
              onChange={handleInputChange("bookingAmount")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Enter status</Typography>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                label="Status"
                value={formData.status}
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                onChange={handleInputChange("status")}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={style.form} gap={2}>
            <Typography variant="h6">Upload images:</Typography>
            <input
              multiple
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Box>

          <Box>
            <Typography variant="h6">Select category:</Typography>
            <FormControl component="fieldset">
              <Box className={classes.twoColumns}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {categories?.map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value._id}
                      control={
                        <Radio
                          onChange={() => handleCategoryChange(value._id)}
                        />
                      }
                      label={value.name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Button variant="contained" sx={style.addBtn} onClick={handleAddTrip}>
        <Typography variant="body2">Add Trip</Typography>
      </Button>
    </Layout>
  );
};

export default AddTrip;
