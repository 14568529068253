import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import Confirm from "../../components/ConfirmMsg";
import AlertMessage from "../../components/Alert";
import { useNavigate } from "react-router-dom/dist";
import apiService from "../../services/apiService";

function SettingsPage() {
  const [settings, setSettings] = useState();

  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    setLoading(true);

    apiService
      .get("/settings/settings")
      .then((response) => {
        const settings = response.data.settings;
        console.log(response);

        setSettings(settings);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [apiService]);

  const handleInputChange = (field) => (event) => {
    console.log(field, event.target.value);
    setSettings({ ...settings, [field]: event.target.value });
  };

  const handleUpdate = () => {
    console.log("Submit Called");
    setLoading(true);

    const requestData = {
      privacy_policy: settings.privacy_policy,
      terms_and_conditions: settings.terms_and_conditions,
      contact_number_1: settings.contact_number_1,
      contact_number_2: settings.contact_number_2,
      contact_email: settings.contact_email,
      contact_email_2: settings.contact_email_2,
      whatsapp_number: settings.whatsapp_number,
      facebook_page: settings.facebook_page,
      instagram_page: settings.instagram_page,
      address: settings.address,
      participateUrl: settings.participateUrl,
    };
    console.log(requestData);

    apiService
      .post("/settings/settings", requestData)
      .then((response) => {
        if (response.data.success) {
          console.log("Settings Updated successfully:", response.data);
          setLoading(false);
        } else {
          console.error("Error:", response.data);
          setLoading(false);
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error("Error posting:", error);
        setLoading(false);
        setOpenSnackbar(true);
      });
  };

  return (
    <Layout>
      <Loader open={loading} />
      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error Updating Settings!"
      />

      <Grid container sx={style.container}>
        <Typography variant="h2">App Settings</Typography>
      </Grid>
      <Box style={style.box}>
        <Typography variant="h6">Privacy policy URL</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.privacy_policy}
          onChange={handleInputChange("privacy_policy")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Terms & Conditions URL</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.terms_and_conditions}
          onChange={handleInputChange("terms_and_conditions")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Contact Number 1</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.contact_number_1}
          onChange={handleInputChange("contact_number_1")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Contact Number 2</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.contact_number_2}
          onChange={handleInputChange("contact_number_2")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Contact Email 1</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.contact_email}
          onChange={handleInputChange("contact_email")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Contact Email 2</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.contact_email_2}
          onChange={handleInputChange("contact_email_2")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">WhatsApp Number</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.whatsapp_number}
          onChange={handleInputChange("whatsapp_number")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Facebook Page Link</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.facebook_page}
          onChange={handleInputChange("facebook_page")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Instagram Page Link</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.instagram_page}
          onChange={handleInputChange("instagram_page")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">Office Address</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.address}
          onChange={handleInputChange("address")}
        />
      </Box>
      <Box style={style.box}>
        <Typography variant="h6">How to Participate Video Link</Typography>
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={loading ? " " : settings.participateUrl}
          onChange={handleInputChange("participateUrl")}
        />
      </Box>
      <Button
        style={style.updateButton}
        variant="contained"
        onClick={handleUpdate}
      >
        Update
      </Button>
    </Layout>
  );
}

export default SettingsPage;
