import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Paper,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const columns = [
  { id: "name", label: "Name", align: "center", sortable: true },
  { id: "email", label: "Email", align: "center", sortable: true },
  { id: "phoneNumber", label: "Phone Number", sortable: false },
  { id: "noOfAdults", label: "Adults", align: "center", sortable: true },
  { id: "noOfChildren", label: "Children", align: "center", sortable: true },
  { id: "noOfInfants", label: "Infants", align: "center", sortable: true },
  { id: "startingCity", label: "Starting City", align: "center", sortable: true },
  { id: "TourStartDate", label: "Tour Start Date", align: "center", sortable: true },
  { id: "TourEndDate", label: "Tour End Date", align: "center", sortable: true },
  { id: "TourDuration", label: "Tour Duration", align: "center", sortable: true },
  { id: "destinations", label: "Destinations", align: "center", sortable: false },
  { id: "adventureStyle", label: "Adventure Style", align: "center", sortable: true },
  { id: "noOfRooms", label: "No of Rooms", align: "center", sortable: true },
  { id: "preferredHotelType", label: "Preferred Hotel Type", align: "center", sortable: true },
  { id: "prefferedRoomType", label: "Preferred Room Type", align: "center", sortable: true },
  { id: "comments", label: "Comments", align: "center", sortable: false },
  { id: "updatedAt", label: "Last Updated At", align: "center", sortable: true },
];

const CustomTours = () => {
  const [customTours, setCustomTours] = useState([]);
  const [filteredCustomTours, setFilteredCustomTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterCustomTours();
  }, [filterStatus, sortColumn, sortDirection, searchQuery, customTours]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await apiService.get("/customTour/get", { headers });

      if (response.data && response.data.customTours) {
        setCustomTours(response.data.customTours.slice().reverse());
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const filterCustomTours = () => {
    let filtered = [...customTours];

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (customTour) =>
          customTour.name?.toLowerCase().includes(query) ||
          customTour.email?.toLowerCase().includes(query) ||
          customTour.phoneNumber?.toString().includes(query)
      );
    }

    // Sort
    if (sortColumn) {
      filtered.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredCustomTours(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDateString = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  const handleSort = (columnId) => {
    let newSortDirection = "asc";

    if (columnId === sortColumn) {
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSortColumn(columnId);
    setSortDirection(newSortDirection);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="success"
        text={message}
      />

      <AlertMessage
        open={openErrorSnackbar}
        onClose={() => setOpenErrorSnackbar(false)}
        severity="error"
        text={message}
      />

      <Grid container sx={{ padding: 2 }}>
        <Typography variant="h3">List of all Users</Typography>
        <Grid item xs={12} md={6}>
          <InputLabel>Status</InputLabel>
          <FormControl sx={{ minWidth: 120, margin: 1 }}>
            <Select
              value={filterStatus}
              onChange={(event) => setFilterStatus(event.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="In Process">In Process</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>Search</InputLabel>
          <TextField
            variant="outlined"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            sx={{ minWidth: 120, margin: 1 }}
          />
        </Grid>

        <Button variant="contained" sx={{ margin: 2 }}>
          <FileDownloadIcon fontSize="small" />

          <CSVLink
            data={filteredCustomTours}
            headers={columns.map((column) => ({
              label: column.label,
              key: column.id,
            }))}
            filename={"users.csv"}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Typography variant="body2">Export</Typography>
          </CSVLink>
        </Button>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      color: "white",
                      backgroundColor: "#2a2f42",
                      cursor: column.sortable ? "pointer" : "default",
                    }}
                    onClick={() => column.sortable && handleSort(column.id)}
                  >
                    <Typography variant="h6">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredCustomTours
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id} hover role="checkbox">
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "destinations"
                          ? row[column.id].join(", ")
                          : column.id === "updatedAt"
                            ? formatDateString(row[column.id])
                            : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={filteredCustomTours.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
};

export default CustomTours;
