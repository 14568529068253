import {
  Box,
  Button,
  Card,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";

import React, { useEffect, useState } from "react";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";
import { Image } from "@mui/icons-material";
import NoData from "../../components/NoData";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";

const AppDashboardSlider = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    getSliderImage();
  }, []);
  const handleDelete = async (id) => {
    try {
      var url = "/slider/slider/" + id;
      var response = await apiService.delete(url);
      if (response.data.success) {
        setSliders((prevSliders) =>
          prevSliders.filter((slider) => slider._id !== id)
        );
        console.log(response);
      }
    } catch (err) {}
  };
  const handleNewSlider = () => {
    navigate("/new-slider-image");
  };
  const getSliderImage = async () => {
    setLoading(true);
    try {
      var response = await apiService.get("/slider/slider");
      console.log(response);
      if (response.data.success) {
        setLoading(false);

        var responseSliders = response.data.sliders;

        setSliders(responseSliders);
      } else {
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error adding a New Lucky Draw trip!"
      />

      <Grid container sx={style.container}>
        <Typography variant="h3">Slider Images</Typography>
        <Button variant="contained" sx={style.button} onClick={handleNewSlider}>
          <Typography variant="body2">Add Slider Image</Typography>
        </Button>
      </Grid>
      <Grid container gap={1}>
        {sliders ? (
          sliders.map((item, index) => (
            <Card style={{ borderRadius: "20px", overflow: "hidden" }}>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.image}
                  alt={item.name}
                  loading="lazy"
                  style={{
                    width: "250px",
                    height: "250px",
                    borderRadius: "",
                  }}
                />
                <Typography style={{ marginTop: "10px", fontWeight: "700" }}>
                  {item.name}
                </Typography>
                <Button
                  variant="contained"
                  style={{ margin: "10px", backgroundColor: "red" }}
                  onClick={() => handleDelete(item._id)}
                >
                  <DeleteForeverIcon></DeleteForeverIcon>
                </Button>
              </Box>
            </Card>
          ))
        ) : (
          <NoData text="trips" />
        )}
      </Grid>
    </Layout>
  );
};

export default AppDashboardSlider;
