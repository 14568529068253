const style = {
  //************ index ************

  container: {
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  box: {
    marginBottom: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  updateButton: {
    width: "250px",
    justifyContent: "center",
  },
};

export default style;
