import React from "react";
import Layout from "../../components/Layout";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SlotMachine } from "./slotMachine";

function AnnounceWinner() {
  return (
    <Layout>
      <Dialog
      //   open={winnerDialogOpen}
      //   onClose={handleCloseWinnerDialog}
      >
        <DialogTitle>Winner Announcement</DialogTitle>
        <DialogContent>
          <SlotMachine
            participants={["Participant 1", "Participant 2", "Participant 3"]}
            // winner={winner}
            // loading={announceWinnerLoading}
          />
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default AnnounceWinner;
