import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Box, Button, Typography } from "@mui/material";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import Confirm from "../../components/ConfirmMsg";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const Addons = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [addonIdToDelete, setAddonIdToDelete] = useState(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    useEffect(() => {
        setLoading(true);
        apiService
            .get("/addons/get")
            .then((response) => {
                const service = response.data.addons;
                setServices(service);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setLoading(false);
            });
    }, []);

    const handleNewAddon = () => {
        navigate("/new-addon");
    };

    const handleEditAddon = (addonId, addonDetails) => {
        navigate(`/edit-addon/${addonId}`, { state: { addonDetails: addonDetails } });
    };

    const handleOpen = (addonId) => {
        setAddonIdToDelete(addonId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAddonIdToDelete(null);
    };

    const handleDelete = async () => {
        setOpen(false);
        setLoading(true);

        try {
            await apiService.delete(`/addons/delete/${addonIdToDelete}`);

            const response = await apiService.get("/addons/get");
            const updatedServices = response.data.addons;

            console.log("Services deleted successfully");
            setServices(updatedServices);
            setOpenSnackbar(true);
        } catch (error) {
            console.error("Error deleting services:", error);
            setOpenErrorSnackbar(true);
        } finally {
            handleClose();
            setLoading(false);
        }
    };

    return (
        <Layout>
            <Loader open={loading} />

            <Confirm
                open={open}
                handleClose={handleClose}
                handleDelete={handleDelete}
            />

            <AlertMessage
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                severity="success"
                text="Service deleted successfully!"
            />

            <AlertMessage
                open={openErrorSnackbar}
                onClose={() => setOpenErrorSnackbar(false)}
                severity="error"
                text="Error deleting service!"
            />

            <Grid container sx={style.container}>
                <Typography variant="h3">List of all Addons</Typography>
                <Button
                    variant="contained"
                    onClick={handleNewAddon}
                    sx={style.button}
                >
                    <Typography variant="body2">Add new Addon</Typography>
                </Button>
            </Grid>

            <Grid container gap={3}>
                {services ? (
                    services.map((value, index) => (
                        <Grid item md={5.75} container gap={1} sx={style.block} key={index}>
                            <Box sx={style.wrap} gap={2}>

                                <Typography variant="body1">{value.name}</Typography>
                                <Typography variant="body1">{value.price}</Typography>


                            </Box>

                            <Box sx={style.wrap} gap={2}>
                                <EditIcon
                                    sx={style.edit}
                                    onClick={() => handleEditAddon(value._id, value)}
                                />
                                <DeleteIcon
                                    sx={style.delete}
                                    color="error"
                                    onClick={() => handleOpen(value._id)}
                                />
                            </Box>
                            <Box sx={style.wrap} gap={2}>

                                <Typography variant="body1">{value.description}</Typography>

                            </Box>
                        </Grid>
                    ))
                ) : (
                    <NoData text="services" />
                )}
            </Grid>
        </Layout>
    );
};

export default Addons;
