import {
  Box,
  Grid,
  Slide,
  Alert,
  Button,
  Snackbar,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import style from "./style";
import logo from "../../images/gpp_logo.gif";
import apiService from "../../services/apiService";

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(/^(\+92)?\d{10}$|^\d{11}$/, "Invalid phone number")
      .required("Required"),
    password: Yup.string().required("Required").min(6),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await apiService.post("/auth/adminLogin", values);

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        console.log("Login successfully", response.data);
        navigate("/trips");
      } else {
        setOpenSnackbar(true);
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setSubmitting(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container sx={style.background}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "right" }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert sx={style.alert} severity="error">
          <Typography variant="subtitl1">
            Invalid phone number or password!
          </Typography>
        </Alert>
      </Snackbar>

      <Grid container sx={style.container}>
        <Formik
          initialValues={{ phoneNumber: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, status }) => (
            <Grid container item md={5}>
              <Form style={style.form}>
                <Box style={style.logoBox}>
                  <img src={logo} alt="Logo" style={style.logoImg} />
                </Box>
                <Grid container gap={3} sx={style.login}>
                  <Typography variant="h1" sx={style.heading}>
                    User Login
                  </Typography>
                  <Box>
                    <Field
                      as={TextField}
                      name="phoneNumber"
                      label="Phone number"
                      variant="outlined"
                      fullWidth
                    />
                    {status && <div style={style.error}>{status}</div>}
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      style={style.error}
                    />
                  </Box>

                  <Box>
                    <Field
                      as={TextField}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={togglePasswordVisibility}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      style={style.error}
                    />
                  </Box>

                  <Link to="#" style={style.forgetPswd}>
                    <Typography variant="body2">Forget Password?</Typography>
                  </Link>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    sx={style.button}
                  >
                    {isSubmitting ? "Logging in..." : "Login"}
                  </Button>
                </Grid>
              </Form>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Login;
