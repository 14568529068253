import {
  Grid,
  Table,
  Paper,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { CSVLink } from "react-csv";
import React, { useState, useEffect } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const columns = [
  { id: "profilePic", label: "Profile Picture", align: "center" },
  { id: "name", label: "Name" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "email", label: "Email" },
  { id: "city", label: "City" },
  { id: "gender", label: "Gender" },
  { id: "balance", label: "Balance", align: "center" },
  { id: "points", label: "Points", align: "center" },
  { id: "admin", label: "Admin", align: "center" },
  { id: "actions", label: "Actions", align: "center" },
];

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [message, setMessage] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        const response = await apiService.get("/auth/getUsers", { headers });

        if (response.data && response.data.users) {
          setUsers(response.data.users);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleAdmin = async (userID) => {
    setLoading(true);
    try {
      // Make API call to toggle admin status
      var adminResponse = await apiService.post(
        `/auth/makeAdmin/${userID}`,
        {}
      );

      setMessage(adminResponse.data.message);

      // Refresh the user data after the admin status is toggled
      const response = await apiService.get("/auth/getUsers");
      const updatedUsers = response.data.users;
      setUsers(updatedUsers);
      setOpenSnackbar(true);
    } catch (error) {
      setOpenErrorSnackbar(true);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="success"
        text={message}
      />

      <AlertMessage
        open={openErrorSnackbar}
        onClose={() => setOpenErrorSnackbar(false)}
        severity="error"
        text={message}
      />

      <Grid container sx={style.container}>
        <Typography variant="h3">List of all Users</Typography>

        <Button variant="contained" sx={style.button}>
          <FileDownloadIcon fontSize="small" />

          <CSVLink
            data={users}
            headers={columns.map((column) => ({
              label: column.label,
              key: column.id,
            }))}
            filename={"users.csv"}
            style={style.csv}
          >
            <Typography variant="body2">Export</Typography>
          </CSVLink>
        </Button>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      color: "white",
                      backgroundColor: "#2a2f42",
                    }}
                  >
                    <Typography variant="h5"> {column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {users &&
                users.length > 0 &&
                users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row._id} hover role="checkbox">
                      {columns.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "profilePic" ? (
                            <img
                              alt={row[column.name]}
                              src={row[column.id]}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : column.id === "admin" ? (
                            row[column.id] ? (
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => toggleAdmin(row._id)}
                              >
                                Remove Admin
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() => toggleAdmin(row._id)}
                              >
                                Make Admin
                              </Button>
                            )
                          ) : (
                            row[column.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
};

export default Users;
