const style = {
  //*********** Common ***********

  wrap: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    height: 40,
    textTransform: "none",
    backgroundColor: "#3d65b0",
  },
  buttons: {
    width: "30%",
    textTransform: "none",
  },

  //************ index ************

  container: {
    marginBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  block: {
    padding: 2,
    borderRadius: 3,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    backgroundColor: "white",
    justifyContent: "space-between",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    width: "50%",
  },

  //************ Add Lucky Draw *************

  flex: {
    display: "flex",
  },
  heading: {
    marginBottom: 5,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  addBtn: {
    marginTop: 6,
    minWidth: "20%",
    textTransform: "none",
    alignSelf: "flex-start",
  },

  //************ View Lucky Draw *************

  detailBlock: {
    padding: 1,
    display: "flex",
    flexDirection: "column",
  },
  action: {
    display: "flex",
    alignSelf: "flex-end",
  },
  width: {
    width: "70%",
  },
  categories: {
    paddingTop: 1,
    paddingLeft: 3,
    paddingRight: 3,
    borderRadius: 2,
    display: "flex",
    paddingBottom: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px solid black",
  },
  slotMachine: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  slotItem: {
    display: "inline-block",
    animation: "scroll 10s linear infinite",
  },
  scroll: {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-100%)",
    },
  },
};

export default style;
