import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Paper,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { CSVLink } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";
import easyPaisaLogo from "../../images/easypaisa.svg";
import jazzcashLogo from "../../images/jazzcash.svg";
import alfaLogo from "../../images/alfalah.png";

const columns = [
  { id: "orderId", label: "Order ID", align: "center", sortable: true },
  { id: "trxId", label: "Transaction ID", align: "center", sortable: true },
  { id: "userId", label: "User Details", sortable: false },
  { id: "amount", label: "Amount", align: "center", sortable: true },
  {
    id: "transactionChannel",
    label: "Payment Method",
    align: "center",
    sortable: true,
  },
  { id: "transactionType", label: "Reason", align: "center", sortable: true },
  { id: "status", label: "Status", align: "center", sortable: true },
  {
    id: "updatedAt",
    label: "Last Updated At",
    align: "center",
    sortable: true,
  },
];

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, []); // Fetch data initially

  useEffect(() => {
    // Filter transactions when filter status, search query, or sort criteria changes
    filterTransactions();
  }, [filterStatus, sortColumn, sortDirection, searchQuery, transactions]); // Rerun when filterStatus, sortColumn, sortDirection, searchQuery, or transactions change

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await apiService.get("/transactions/transaction", {
        headers,
      });

      if (response.data && response.data.transactions) {
        setTransactions(response.data.transactions.slice().reverse());
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const filterTransactions = () => {
    let filtered = [...transactions];

    // Filter by status
    if (filterStatus) {
      filtered = filtered.filter(
        (transaction) => transaction.status === filterStatus
      );
    }

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (transaction) =>
          transaction.orderId?.toLowerCase().includes(query) ||
          transaction.trxId?.toLowerCase().includes(query) ||
          transaction.userId?.email?.toLowerCase().includes(query) ||
          (transaction.userId?.phoneNumber &&
            transaction.userId.phoneNumber.toLowerCase().includes(query)) ||
          (transaction.userId?.name &&
            transaction.userId.name.toLowerCase().includes(query))
      );
    }

    // Sort
    if (sortColumn) {
      filtered.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredTransactions(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDateString = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  const handleSort = (columnId) => {
    let newSortDirection = "asc";

    if (columnId === sortColumn) {
      // Toggle sort direction if sorting the same column
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSortColumn(columnId);
    setSortDirection(newSortDirection);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="success"
        text={message}
      />

      <AlertMessage
        open={openErrorSnackbar}
        onClose={() => setOpenErrorSnackbar(false)}
        severity="error"
        text={message}
      />

      <Grid container sx={style.container}>
        <Typography variant="h3">List of all Users</Typography>
        <Grid>
          <InputLabel>Status</InputLabel>
          <FormControl sx={style.filterControl}>
            <Select
              value={filterStatus}
              onChange={(event) => setFilterStatus(event.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="In Process">In Process</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid>
          <InputLabel>Search</InputLabel>
          <TextField
            variant="outlined"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            sx={style.filterControl}
          />
        </Grid>

        <Button variant="contained" sx={style.button}>
          <FileDownloadIcon fontSize="small" />

          <CSVLink
            data={filteredTransactions}
            headers={columns.map((column) => ({
              label: column.label,
              key: column.id,
            }))}
            filename={"users.csv"}
            style={style.csv}
          >
            <Typography variant="body2">Export</Typography>
          </CSVLink>
        </Button>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      color: "white",
                      backgroundColor: "#2a2f42",
                      cursor: column.sortable ? "pointer" : "default",
                    }}
                    onClick={() => column.sortable && handleSort(column.id)}
                  >
                    <Typography variant="h5"> {column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredTransactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id} hover role="checkbox">
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "profilePic" ? (
                          <img
                            alt={row[column.name]}
                            src={row[column.id]}
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : column.id === "userId" ? (
                          <>
                            {row.userId === null ? (
                              <Typography variant="body2">
                                User does Not Exist
                              </Typography>
                            ) : (
                              <>
                                {typeof row.userId === "object" && (
                                  <>
                                    <Typography variant="body2">
                                      {row.userId.phoneNumber || ""}
                                    </Typography>
                                    <Typography variant="body2">
                                      {row.userId.email || ""}
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : column.id === "transactionChannel" ? (
                          <>
                            {row.transactionChannel === "Paymob/EasyPaisa" ||
                            row.transactionChannel === "Easy Paisa" ||
                            row.transactionChannel === "EasyPaisa" ? (
                              <img
                                src={easyPaisaLogo}
                                alt="SVG as an image"
                                width="60px"
                              />
                            ) : row.transactionChannel === "Paymob/JazzCash" ||
                              row.transactionChannel === "Jazz Cash" ||
                              row.transactionChannel === "Paymob/Jazzcash" ? (
                              <img
                                src={jazzcashLogo}
                                alt="SVG as an image"
                                width="60px"
                              />
                            ) : row.transactionChannel === "Alfa" ||
                              row.transactionChannel === "Credit/Debit Card" ||
                              row.transactionChannel === "Credit/Debit" ? (
                              <img
                                src={alfaLogo}
                                alt="SVG as an image"
                                width="60px"
                              />
                            ) : (
                              <Typography>{row.transactionChannel}</Typography>
                            )}
                          </>
                        ) : column.id === "updatedAt" ? (
                          <>{formatDateString(row[column.id])}</>
                        ) : column.id === "amount" ? (
                          <>{row[column.id]} PKR</>
                        ) : (
                          row[column.id]
                        )}
                        {/* <img src={logo} alt="SVG as an image" /> */}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 30, 50]}
          component="div"
          count={filteredTransactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Layout>
  );
};

export default Transactions;
