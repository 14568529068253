const styles = {
  alignment: {
    marginTop: "40px",
  },
  alert: {
    alignItems: "center",
  },
};

export default styles;
