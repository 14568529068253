import { Avatar, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const SlotMachine = ({ participants, loading, winner }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let intervalId;

    const startAnimation = () => {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % participants.length);
      }, 500);
    };

    const stopAnimation = () => {
      clearInterval(intervalId);
    };

    if (loading) {
      startAnimation();
    } else {
      stopAnimation();
    }

    return () => {
      stopAnimation();
    };
  }, [loading, participants.length]);

  return (
    <div
      style={{
        overflow: "hidden",
        height: "500px",
        width: "500px",
        display: "flex", // Use flex container
        flexDirection: "column", // Column layout
        alignItems: "center", // Center items horizontally
        justifyContent: "center", // Center items vertically
      }}
    >
      <Typography variant="h4">
        {participants.map((participant, index) => (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={index}
              src={winner ? winner.profilePic : "/static/images/avatar/1.jpg"}
              style={{
                display: index === currentIndex ? "flex" : "none",
                width: "300px", // Adjust the width as needed
                height: "300px", // Adjust the height as needed
              }}
              gap={3}
            />

            <Box style={{ width: "15px" }}></Box>

            <span
              key={index}
              style={{
                display: index === currentIndex ? "block" : "none",
                fontSize: "40px",
              }}
            >
              {winner ? winner.name : participant}
            </span>
          </Box>
        ))}
      </Typography>
    </div>
  );
};
