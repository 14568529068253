import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const developmentEnvironment = process.env.DEVELOPMENT;
const apiService = axios.create({
  baseURL: baseUrl, // Your API base URL
});

apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  // Add Authorization header if the token exists
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

if (!developmentEnvironment) {
  // Add a response interceptor
  apiService.interceptors.response.use(
    (response) => {
      // Modify the URLs in the response data
      if (response.data && typeof response.data === "object") {
        response.data = modifyUrls(response.data);
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Function to modify URLs in an object
  function modifyUrls(obj) {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
      return obj.map((item) => modifyUrls(item));
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = modifyUrls(obj[key]);
        }
      }
    } else if (typeof obj === "string") {
      // Replace the URLs in strings
      obj = obj.replace(
        /http:\/\/gpp\.codeminer\.co:3001/g,
        "https://gpp.codeminer.co:3003"
      );
    }

    return obj;
  }
}

export default apiService;
