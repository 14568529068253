import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, TextField, Button, Typography } from "@mui/material";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const AddAddon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        price: "",
    });

    const handleInputChange = (field) => (event) => {
        setFormData({ ...formData, [field]: event.target.value });
    };



    const handleAddAddon = () => {
        setLoading(true);

        const token = localStorage.getItem("token");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };

        // Use FormData to handle file uploads
        const formDataForApi = new FormData();
        formDataForApi.append("name", formData.name);
        formDataForApi.append("description", formData.description);
        formDataForApi.append("price", formData.price);

        apiService
            .post("/addons/add", formDataForApi, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.success) {
                    console.log("Addons API:", response.data);
                    setLoading(false);
                    navigate("/addons");
                } else {
                    console.error("Error:", response.data.error);
                    setLoading(false);
                    setOpenSnackbar(true);
                }
            })
            .catch((error) => {
                console.error("Error", error);
                setLoading(false);
                setOpenSnackbar(true);
            });
    };

    return (
        <Layout>
            <Loader open={loading} />

            <AlertMessage
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                severity="error"
                text="Error adding a new Addon!"
            />

            <Grid container gap={10} sx={style.flex}>
                <Box sx={style.form} gap={2}>
                    <Typography variant="h6">Enter Name</Typography>
                    <TextField
                        size="small"
                        sx={style.label}
                        value={formData.name}
                        onChange={handleInputChange("name")}
                    />
                </Box>

                <Box sx={style.form} gap={2}>
                    <Typography variant="h6">Enter Description</Typography>
                    <TextField
                        size="small"
                        sx={style.label}
                        value={formData.description}
                        onChange={handleInputChange("description")}
                    />
                </Box>

                <Box sx={style.form} gap={2}>
                    <Typography variant="h6">Enter price</Typography>
                    <TextField
                        size="small"
                        sx={style.label}
                        value={formData.price}
                        onChange={handleInputChange("price")}
                    />
                </Box>


            </Grid>

            <Button variant="contained" sx={style.addBtn} onClick={handleAddAddon}>
                <Typography variant="body2">Add Addons</Typography>
            </Button>
        </Layout>
    );
};

export default AddAddon;
