import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
} from "@mui/material";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import NoData from "../../components/NoData";
import AlertMessage from "../../components/Alert";
import { useSpring } from "@react-spring/web";
import apiService from "../../services/apiService";
import Confirm from "../../components/ConfirmMsg";
import { SlotMachine } from "./slotMachine";

const LuckyDraw = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [luckyDraws, setLuckyDraws] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [tripIdToDelete, setTripIdToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [announceWinnerLoading, setAnnounceWinnerLoading] = useState(false);
  const [winnerDialogOpen, setWinnerDialogOpen] = useState(false);
  const [winner, setWinner] = useState(null);
  const [openDeleteSuccessSnackbar, setOpenDeleteSuccessSnackbar] =
    useState(false);
  const [participants, setParticipants] = useState([
    "Participant 1",
    "Participant 2",
    "Participant 3",
  ]);

  const [springProps, setSpringProps] = useSpring(() => ({
    translateY: 0,
    config: { duration: 500 }, // Adjust duration as needed
  }));

  useEffect(() => {
    setLoading(true);

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiService.get("/lottery/lottery");
      const luckyDraw = response.data.lotteries;
      setLuckyDraws(luckyDraw);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let intervalId;

    const startAnimation = () => {
      intervalId = setInterval(() => {
        setSpringProps({ translateY: (prev) => prev - 50 });
      }, 500); // Adjust the interval as needed
    };

    const stopAnimation = () => {
      clearInterval(intervalId);
      setSpringProps({ translateY: 0 });
    };

    if (announceWinnerLoading) {
      startAnimation();
    } else {
      stopAnimation();
    }

    return () => {
      stopAnimation(); // Cleanup the interval on component unmount
    };
  }, [announceWinnerLoading, setSpringProps, luckyDraws]);

  const getParticipants = async ({ lotteryID }) => {
    try {
      const token = localStorage.getItem("token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await apiService.get(
        `/lottery/participants/${lotteryID}`,
        {
          headers,
        }
      );

      // Process the response as needed
      const participants = response.data;

      return participants;
    } catch (error) {
      // Handle errors, e.g., log or throw an exception
      console.error("Error:", error);
      throw error;
    }
  };

  const handleOpen = (categoryId) => {
    setTripIdToDelete(categoryId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTripIdToDelete(null);
  };

  const handleDelete = async () => {
    setOpen(false);
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      console.log(tripIdToDelete);
      const deleteResponse = await apiService.delete(
        `/lottery/lottery/${tripIdToDelete}`,
        {
          headers,
        }
      );
      console.log(deleteResponse);

      if (deleteResponse.data.success === true) {
        fetchData();
        setLoading(false);
      } else {
        setErrorMessage(deleteResponse.data.message);
        setLoading(false);
        setOpenDeleteSuccessSnackbar(true);
      }
    } catch (error) {
      console.error("Error deleting trip:", error);
      setErrorMessage(error);
      setLoading(false);
      setOpenErrorSnackbar(true);
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleNewLuckyDraw = () => {
    navigate("/new-lucky-draw");
  };

  const handleViewDetails = (luckyDrawId, luckyDrawDetails) => {
    navigate(`/view-lucky-draw/${luckyDrawId}`, {
      state: { luckyDrawDetails },
    });
  };

  const handleAnnounceWinner = async (luckyDrawId) => {
    setAnnounceWinnerLoading(true);

    try {
      // Make API call to announce the winner
      const response = await apiService.post("/lottery/announceWinner", {
        lotteryId: luckyDrawId,
      });

      console.log(response.data);

      if (response.data.success) {
        // Display the winner in the dialog
        setTimeout(() => {
          setWinner(response.data.winner);
        }, 5000);
        setWinnerDialogOpen(true);
      } else {
        console.error("Error:", response.data.error);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTimeout(() => {
        setAnnounceWinnerLoading(false);
      }, 5000); // 10 seconds delay
    }
  };

  const handleCloseWinnerDialog = () => {
    setWinnerDialogOpen(false);
  };

  const formatDateString = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  return (
    <Layout>
      <Loader open={loading} />

      <Confirm
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="success"
        text="Winner has been announced!"
      />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenDeleteSuccessSnackbar(false)}
        severity="success"
        text="Lucky Draw has been deleted Successful"
      />

      <Dialog open={winnerDialogOpen} onClose={handleCloseWinnerDialog}>
        <DialogTitle>Winner Announcement</DialogTitle>
        <DialogContent>
          <SlotMachine
            participants={["Participant 1", "Participant 2", "Participant 3"]}
            winner={winner}
            loading={announceWinnerLoading}
          />
        </DialogContent>
      </Dialog>

      <Grid container sx={style.container}>
        <Typography variant="h3">List of all Lucky draws</Typography>
        <Button
          variant="contained"
          onClick={handleNewLuckyDraw}
          sx={style.button}
        >
          <Typography variant="body2">Add new Lucky draw</Typography>
        </Button>
      </Grid>

      <Grid container gap={5}>
        {luckyDraws ? (
          luckyDraws.map((value, index) => (
            <Grid item md={4.5} container gap={2} sx={style.block} key={index}>
              <Typography variant="h4">{value.name}</Typography>

              <Box sx={style.wrapper} gap={1}>
                <Box sx={style.wrap} gap={1}>
                  <Typography variant="h5">Join price:</Typography>
                  <Typography variant="body1">{value.joinPrice}</Typography>
                </Box>

                <Box sx={style.wrap} gap={1}>
                  <Typography variant="h5">Win:</Typography>
                  <Typography variant="body1">
                    {value.lotteryTrip.name
                      ? value.lotteryTrip.name
                      : "No Trip Found"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={style.wrap} gap={1}>
                <Typography variant="h5">End time:</Typography>
                <Typography variant="body1">
                  {formatDateString(value.endTime)}
                </Typography>
              </Box>

              <Grid container sx={style.wrapper}>
                <Button
                  sx={style.buttons}
                  variant="outlined"
                  onClick={() => handleViewDetails(value._id, value)}
                >
                  <Typography variant="body1">View Details</Typography>
                </Button>

                <Button
                  sx={style.buttons}
                  variant="outlined"
                  onClick={() => handleAnnounceWinner(value._id)}
                >
                  <Typography variant="body1">Announce Winner</Typography>
                </Button>
                <Button
                  sx={style.buttons}
                  color="error"
                  variant="outlined"
                  onClick={() => handleOpen(value._id)}
                >
                  <Typography variant="body1">Delete</Typography>
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <NoData text="lucky draws" />
        )}
      </Grid>
    </Layout>
  );
};

export default LuckyDraw;
