import {
  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  FormGroup,
  InputLabel,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DateTimePicker } from "@mui/x-date-pickers";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const useStyles = makeStyles({
  twoColumns: {
    columnCount: 2,
    marginTop: 10,
  },
});

const EditTrip = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [services, setServices] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const initialTripData = state?.tripDetails;

  // const formattedStartDate = initialTripData.startDate
  //   ? initialTripData.startDate.map((date) => dayjs(date))
  //   : null;

  const [formData, setFormData] = useState({
    id: initialTripData._id,
    name: initialTripData.name,
    description: initialTripData.description,
    price: initialTripData.price,
    seatOfChoicePrice: initialTripData.seatOfChoicePrice,
    couplePrice: initialTripData.couplePrice,
    deluxePrice: initialTripData.deluxePrice,
    deluxeCouplePrice: initialTripData.deluxeCouplePrice,
    foldingSeatPrice: initialTripData.foldingSeatPrice,
    bookingAmount: initialTripData.bookingAmount,
    loyaltyPoints: initialTripData.loyaltyPoints,
    days: initialTripData.days,
    startDate: initialTripData.startDate,
    status: initialTripData.status,
    services: initialTripData.services,
    addons: initialTripData.addons,
    category: initialTripData.category,
  });

  useEffect(() => {
    setLoading(true);
    console.log(initialTripData.category._id);

    setSelectedDates(initialTripData.startDate);
    setSelectedCategory(initialTripData.category._id);

    apiService
      .get("/services/service")
      .then((response) => {
        const service = response.data.services;
        setServices(service);
        setAddOns(service);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });

    apiService
      .get("/categories/category")
      .then((response) => {
        const category = response.data.categories;
        setCategories(category);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [selectedServices, setSelectedServices] = useState(
    initialTripData.services.map((service) => service._id) || []
  );

  const [selectedAddOns, setSelectedAddOns] = useState(
    initialTripData.addOns.map((addon) => addon._id) || []
  );

  const handleServiceChange = (serviceId) => {
    setSelectedServices((prevSelectedServices) => {
      const isSelected = prevSelectedServices.includes(serviceId);

      if (isSelected) {
        // If serviceId is already in the list, remove it
        const updatedServices = prevSelectedServices.filter(
          (id) => id !== serviceId
        );
        return updatedServices;
      } else {
        // If serviceId is not in the list, add it
        const updatedServices = [...prevSelectedServices, serviceId];
        return updatedServices;
      }
    });
  };

  const handleAddOnChange = (addOnId) => {
    setSelectedAddOns((prevSelectedAddons) => {
      const isSelected = prevSelectedAddons.includes(addOnId);

      if (isSelected) {
        // If serviceId is already in the list, remove it
        const updatedAddOns = prevSelectedAddons.filter(
          (id) => id !== addOnId
        );
        return updatedAddOns;
      } else {
        // If serviceId is not in the list, add it
        const updatedAddOns = [...prevSelectedAddons, addOnId];
        return updatedAddOns;
      }
    });
  };
  const getFormattedDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleDateChange = (field) => (date) => {
    const formattedDate = date.toISOString();
    setSelectedDates([...selectedDates, formattedDate]);
    setFormData({ ...formData, [field]: [...selectedDates, formattedDate] });
    console.log(selectedDates);
  };

  // const handleDateChange = (field) => (date) => {
  //   const dayjsDate = dayjs(date);
  //   setSelectedDates([...selectedDates, date]);
  //   setFormData({ ...formData, [field]: [...selectedDates, dayjsDate] });
  //   console.log(selectedDates);
  // };

  const handleDeleteDate = (index) => {
    const updatedDates = [...selectedDates];
    updatedDates.splice(index, 1);
    setSelectedDates(updatedDates);
    setFormData({ ...formData, startDate: updatedDates });
  };

  const handleInputChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleEditTrip = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const updatedFormData = {
      ...formData,
      services: selectedServices,
      category: selectedCategory,
    };
    console.log(updatedFormData);
    apiService
      .put("/trips/trip", updatedFormData, {
        headers: headers,
      })
      .then((response) => {
        const data = response.data;

        if (data.success) {
          setLoading(false);
          navigate("/trips");
          setOpenSnackbar(true);
        } else console.error("Error:", data.error);
      })
      .catch((error) => {
        console.error("Error", error);
        setLoading(false);
        setOpenSnackbar(true);
      });
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error updating this trip!"
      />

      <Typography variant="h3" sx={style.heading}>
        Edit trip
      </Typography>

      <Grid container gap={8} sx={style.flex}>
        <Grid
          container
          item
          md={4}
          sm={12}
          xs={12}
          gap={2}
          sx={style.formContainer}
        >
          <Box sx={style.form}>
            <Typography variant="h6">Name:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.name}
              onChange={handleInputChange("name")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Standard Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.price}
              onChange={handleInputChange("price")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Standard Couple Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.couplePrice}
              onChange={handleInputChange("couplePrice")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Deluxe Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.deluxePrice}
              onChange={handleInputChange("deluxePrice")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Deluxe Couple Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.deluxeCouplePrice}
              onChange={handleInputChange("deluxeCouplePrice")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Seat of Your Choice Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.seatOfChoicePrice}
              onChange={handleInputChange("seatOfChoicePrice")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Folding Seat Price:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.foldingSeatPrice}
              onChange={handleInputChange("foldingSeatPrice")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Booking Amount:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.bookingAmount}
              onChange={handleInputChange("bookingAmount")}
            />
          </Box>
          <Box sx={style.form}>
            <Typography variant="h6">Loyality Points:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.loyaltyPoints}
              onChange={handleInputChange("loyaltyPoints")}
            />
          </Box>

          <Box sx={style.form}>
            <Typography variant="h6">Days:</Typography>
            <TextField
              size="small"
              sx={style.label}
              variant="outlined"
              value={formData.days}
              onChange={handleInputChange("days")}
            />
          </Box>

          <Box sx={style.formContainer}>
            <Typography variant="h6">Add start date:</Typography>
            <Grid container alignItems="center" justify="space-between" gap={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    label="Start date"
                    onAccept={handleDateChange("startDate")}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Box>
          {selectedDates.length > 0 && (
            <Box sx={style.formContainer} gap={2}>
              <Typography variant="h6">Selected Start Dates</Typography>
              {selectedDates.map((item, index) => (
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  gap={2}
                  key={index} // Add a unique key for each grid container
                >
                  <Typography variant="h6" color="green">
                    {getFormattedDate(item)}
                  </Typography>
                  <div style={{ marginLeft: "auto" }}>
                    <DeleteForeverIcon
                      style={{ color: "red" }}
                      onClick={() => handleDeleteDate(index)}
                    />
                  </div>
                </Grid>
              ))}
            </Box>
          )}

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                sx={style.dates}
                label="Select start date"
                value={formData.startDate}
                onChange={handleDateChange("startDate")}
              />
            </DemoContainer>
          </LocalizationProvider> */}
          <Box>
            <Typography variant="h6">Choose services:</Typography>
            <Box className={classes.twoColumns}>
              {services?.map((value) => (
                <FormGroup key={value._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedServices?.some(
                          (service) => service === value._id
                        )}
                        onChange={() => handleServiceChange(value._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={value.name}
                  />
                </FormGroup>
              ))}
            </Box>
          </Box>

          <Box>
            <Typography variant="h6">Choose Add On Services:</Typography>
            <Box className={classes.twoColumns}>
              {addOns?.map((value) => (
                <FormGroup key={value._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAddOns?.some(
                          (addon) => addon === value._id
                        )}
                        onChange={() => handleAddOnChange(value._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={value.name}
                  />
                </FormGroup>
              ))}
            </Box>
          </Box>

          <Box>
            <Typography variant="h6">Select category:</Typography>
            <FormControl component="fieldset">
              <Box className={classes.twoColumns}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {categories?.map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value._id}
                      control={
                        <Radio
                          checked={selectedCategory === value._id}
                          onChange={() => handleCategoryChange(value._id)}
                        />
                      }
                      label={value.name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </FormControl>
          </Box>
          <Button
            variant="contained"
            sx={style.addBtn}
            onClick={handleEditTrip}
          >
            <Typography variant="body2">Edit Trip</Typography>
          </Button>
        </Grid>

        <Grid
          item
          md={7}
          sm={12}
          xs={12}
          gap={4}
          container
          sx={style.formContainer}
        >
          <Box sx={style.desc} gap={1}>
            <Typography variant="h6">Description:</Typography>
            <ReactQuill
              value={formData.description}
              onChange={(value) =>
                setFormData({ ...formData, description: value })
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EditTrip;
