import { Box, Grid, Button, TextField, Typography } from "@mui/material";

import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import style from "./style";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import AlertMessage from "../../components/Alert";
import apiService from "../../services/apiService";

const AddSliderImage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    file: [],
  });

  const handleInputChange = (field) => (event) => {
    console.log(field, event.target.value);
    setFormData({ ...formData, [field]: event.target.value });
  };

  //   const handleImageChange = (field) => (event) => {
  //     console.log(field, event.target.files);
  //     setFormData({ ...formData, [field]: event.target.file });
  //   };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setFormData({ ...formData, file: files });
  };

  const handleAddSlider = () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    const formDataForApi = new FormData();
    formDataForApi.append("name", formData.name);
    formData.file.forEach((file) => {
      formDataForApi.append("file", file);
    });
    console.log(formDataForApi.entries);
    // Make API call to add lucky draw trip
    apiService
      .post("/slider/slider", formDataForApi, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          console.log("Slider Image added successfully:", response.data);
          setLoading(false);
          navigate("/slider-images");
        } else {
          console.error("Error:", response.data);
          setLoading(false);
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        console.error("Error posting:", error);
        setLoading(false);
        setOpenSnackbar(true);
      });
  };

  return (
    <Layout>
      <Loader open={loading} />

      <AlertMessage
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        severity="error"
        text="Error adding a New Slider Image!"
      />

      <Typography variant="h3" sx={style.heading}>
        Add a New Slider Image
      </Typography>

      <Grid container gap={8} sx={style.flex}>
        <Grid container item md={5} sx={style.formContainer} gap={4}>
          <Box sx={style.form} gap={2}>
            <Typography variant="h6">Enter Name</Typography>
            <TextField
              size="small"
              sx={style.label}
              value={formData.name}
              onChange={handleInputChange("name")}
            />
          </Box>

          <Box sx={style.form} gap={2}>
            <Typography variant="h6">Upload image:</Typography>
            <input
              multiple
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </Box>
        </Grid>
      </Grid>

      <Button variant="contained" sx={style.addBtn} onClick={handleAddSlider}>
        <Typography variant="body2">Add Slider Image</Typography>
      </Button>
    </Layout>
  );
};

export default AddSliderImage;
